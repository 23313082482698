import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import EmptySpace from "@components/ui/empty-space";
import Hero from "@components/ui/hero";
import Layout from "@components/domain/shared/layout";
import Spacing from "@components/ui/spacing";
import Title from "@components/ui/title";
import SEO from "@components/ui/seo";
import Accordion from "@components/ui/accordion";
import styles from "@styles/pages/perguntas-frequentes.module.scss";

const faq = [
  {
    title: "O que é o After School?",
    value:
      "São cursos de Ciência, Tecnologia, Engenharia e Matemática, online e presenciais, para alunos do 5º ao 12º ano de escolaridade.",
  },
  {
    title: "Como funciona o After School?",
    value:
      "Num regime de ‘aula invertida’. A aula invertida tem dois momentos. No primeiro momento, o aluno é responsável por estudar a matéria que lhe é indicada (corresponde a cerca de 4 horas de trabalho autónomo). No segundo momento, o aluno participa numa sessão com um instrutor, durante a qual esclarece dúvidas e consolida a matéria (estas sessões costumam ter a duração de 2 horas).",
  },
  {
    title: "Quem é que se pode candidatar ao After School?",
    value:
      "Qualquer aluno, de qualquer parte do mundo, desde que se enquadre nos anos de escolaridade especificados para o curso a que se quer candidatar.",
  },
  {
    title: "Como é que me candidato ao After School?",
    value:
      "Carregando no botão 'candidata-te a este curso', que surge na página dos cursos com candidaturas abertas.",
  },
  {
    title: "Quando é que abrem candidaturas para o After School?",
    value:
      "Todos os meses.",
  },
  {
    title: "Onde posso encontrar informações sobre os cursos, tais como horários, duração, anos de escolaridade e outros?",
    value:
      "Na página de cada curso. Se estiveres a consultar o site num computador, a informação surge do lado direito. Se estiveres a consultar o site num telemóvel, a informação surge quando arrastas para cima a seta que está na base do ecrã.",
  },
  {
    title: "Gostava de participar em mais do que um curso. É possível?",
    value:
      "Sim. Candidata-te a todos os cursos em que estejas interessado e para o qual tenhas disponibilidade de horário. No entanto, para dar oportunidade a mais alunos e dado que o número de vagas é menor do que o número de candidaturas, será pouco provável que sejas selecionado para participar em dois cursos em simultâneo.",
  },
  {
    title: "Quanto é que se paga para participar no After School?",
    value:
      "Pagas o que puderes. Os cursos After School seguem o modelo 'pay what you can'. Isto significa que cada um paga aquilo que achar justo pela sua participação. O After School é organizado pela TreeTree2, uma associação sem fins lucrativos que visa despertar e desenvolver talentos na área da Ciência, Tecnologia, Engenharia e Matemática. Acreditamos que qualquer aluno com talento, independentemente das suas capacidades financeiras, deve poder participar no After School. Como tal, deixamos que sejam os encarregados de educação a definir o valor do seu apoio ao After School. Qualquer valor doado à TreeTree2 será investido na manutenção e expansão das suas atividades.",
  },
  {
    title: "Gostava de participar mas não consigo dedicar as horas necessárias e/ou não consigo estar presente nas sessões acompanhadas. O que devo fazer?",
    value:
      "Candidata-te numa altura em que tenhas mais disponibilidade. O After School é apenas para alunos que estejam disponíveis para trabalhar e aprender.",
  },
  {
    title:
      "O meu ano de escolaridade não aparece em nenhum os cursos After School. Isso quer dizer que o After School não é para mim?",
    value:
      "Não. Mesmo que os cursos que estejam abertos não se enquadrem no teu ano de escolaridade, isso não quer dizer que o After School não é para ti. Nós pretendemos alargar o After School a todos os anos de escolaridade. Por isso é bem possível que, brevemente, tenhas cursos para os teus anos de escolaridade. Subscreve a nossa newsletter e segue-nos nas redes sociais para receberes todas as informações.",
  },
  {
    title:
      "Gostei de um curso mas os conteúdos parecem-me muito avançados e não possuo conhecimentos sobre eles. No entanto, gostaria muito de aprender e enquadro-me nos anos de escolaridade requeridos. O que devo fazer?",
    value:
      "Candidata-te. A ideia do After School é aprender matérias que não se aprendem na escola (ou aprendê-las mais cedo).",
  },
];

const Privacy = () => {
  const data = useStaticQuery(graphql`
    query FAQBackgroundImageQuery {
      backgroundImage: file(name: { eq: "homepage-hero" }) {
        name
        internal {
          mediaType
        }
        childImageSharp {
          fixed(width: 1260) {
            height
            width
            src
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <Hero />
      <SEO
        title="Perguntas frequentes"
        description="afterschool. Cursos extracurriculares de excelência em ciência, tecnologia, engenharia e matemática."
        image={{
          src: data.backgroundImage.childImageSharp.fixed.src,
          height: data.backgroundImage.childImageSharp.fixed.height,
          width: data.backgroundImage.childImageSharp.fixed.width,
          name: data.backgroundImage.name,
          mediaType: data.backgroundImage.internal.mediaType,
        }}
      />

      <EmptySpace desktop={{ margin: 120 }} mobile={{ margin: 100 }} />
      <div className={styles.container}>
        <Spacing>
          <Title value="Perguntas frequentes" maxWidth="300px" />
        </Spacing>

        <section className={styles.faqSection}>
          {faq.length && (
            <Accordion
              desktopStyles={{ marginBottom: 30 }}
              mobileStyles={{ marginBottom: 20 }}
              allowMultiple={true}
            >
              {faq.map((item, index) => (
                <div key={`${index}-${item.title}`} label={item.title}>
                  <p className={styles.answerSection}>{item.value}</p>
                </div>
              ))}
            </Accordion>
          )}
        </section>

        <EmptySpace
          desktop={{ margin: 180, isLineVisible: false }}
          mobile={{ margin: 80, isLineVisible: false }}
        />
      </div>
    </Layout>
  );
};

export default Privacy;
